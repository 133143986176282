@import '../../styles/variables.scss';

.createPost {
  width: 100vw;
  background-color: $black70;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 10;

  .contentSection {
    position: absolute;
    left: 0;
    width: 100vw;
    bottom: 0;
    border-radius: 24px 24px 0px 0px;
    background-color: $borderWhite;
    backdrop-filter: blur(60px);

    .titleHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px 28px;
      border-bottom: 1px solid $borderWhite;

      p {
        &:first-child {
          font-size: 16px;
          font-weight: 600;
          line-height: 148%;
          color: $white;
        }

        &.crossIcon {
          width: 32px;
          height: 32px;
          padding: 8px;
          cursor: pointer;
          position: relative;

          &:hover {
            background: $translucentWhite;
            border-radius: 100%;
          }

          &:after,
          &:before {
            position: absolute;
            content: '';
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(45deg);
            width: 2px;
            height: 20px;
            background-color: $white80;
          }

          &:after {
            transform: translate(-50%, -50%) rotate(-45deg);
          }
        }
      }
    }

    .postUploadedSection {
      display: flex;
      padding: 24px;
      padding-top: 16px;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      align-self: stretch;

      .postUploaded {
        display: flex;
        align-items: center;
        gap: 10px;
        width: 100%;

        .postLink {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 14px;
          font-weight: 400;
          line-height: 14px;
          color: $white70;
          padding: 13px 16px;
          border-radius: 24px;
          border: 1px solid $borderWhite;
          flex: 1 0 0;
          box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.04);
          background: linear-gradient(90deg, rgba(59, 110, 113, 0.30) 0%, rgba(62, 83, 137, 0.30) 50%, rgba(52, 46, 122, 0.30) 100%), $borderWhite;
        }

        img {
          width: 40px;
          height: 40px;
          flex-shrink: 0;
          cursor: pointer;
          border-radius: 50%;
        }

        button {
          width: auto;
          flex-shrink: 0;
        }
      }

      .sharePostSection {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 12px;
        align-self: stretch;

        .sharePost {
          color: $darkBlack;
          font-size: 14px;
          font-weight: 400;
          flex: 1;
        }

        .socialIcon {
          width: 40px;
          height: 40px;
          cursor: pointer;
        }
      }
    }
  }

  &.desktopCreatePost {
    .contentSection {
      width: 480px;
      left: 50%;
      top: 50%;
      bottom: unset;
      transform: translate(-50%, -50%);
      border-radius: 24px;
    }
  }
}