@import '../../styles/variables';


.contentSection {
  display: flex;
  width: calc(100% - 24px);
  max-width: 420px;
  padding: 20px 36px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  border-radius: 24px;
  background: $borderWhite;
  backdrop-filter: blur(60px);
  height: fit-content;
  align-self: center;
  margin: auto;

  .avatarSection {
    border: 1px solid $translucentWhite;
    border-radius: 50%;
    flex-shrink: 0;
    overflow: hidden;
    width: 94px;
    height: 94px;

    .blurOverlay {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 68.75%, rgba(0, 0, 0, 0.60) 100%);
      backdrop-filter: blur(5px);
      height: 94px;
      width: 94px;
      position: absolute;
      top: 20px;
      border-radius: 50%;
      overflow: hidden;
    }

    .lockImg {
      background-color: $redAlert;
      position: absolute;
      border-radius: 50%;
      padding: 10px;
      transform: translate(-42px, 50px);
    }
  }

  .charInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    text-align: center;

    .title {
      font-size: 20px;
      font-weight: 600;
      color: $white;
    }

    .subTitle {
      font-size: 18px;
      font-weight: 600;
      color: $white;
    }

    .info {
      font-size: 14px;
      font-weight: 400;
      color: $white70;
    }
  }

  button {
    max-width: 248px;
    height: 40px;
  }


  .divider {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    width: 100%;

    span {

      &:first-child,
      &:last-child {
        flex-grow: 1;
        height: 1px;
        background-color: $translucentWhite;
      }

      &:nth-child(2) {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 400;
        line-height: 130%;
        color: $translucentWhite;
        margin: 0 10px;
      }
    }
  }

  .loginSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    text-align: center;

    .title {
      font-size: 20px;
      font-weight: 600;
      color: $white;
    }

    .info {
      font-size: 14px;
      font-weight: 400;
      color: $white70;
    }
  }
}

.loadingContainer {
  justify-content: center;
  padding: 8px;
  display: flex;
  align-items: center;
  z-index: 2;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  z-index: 2;
  left: 0vw;

  &.desktopLoader {
    left: 23.5vw;
    width: 76.5vw;
  }

  &.chatSection {
    left: 0;
  }
}