@import '../../styles/variables';
@import '../../styles/breakpoints';

.chatStats {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  border-radius: 20px;
  backdrop-filter: blur(12px);
  background-color: $black70;
  flex-shrink: 0;
  img {
    width: 16px !important;
    height: auto;
    margin-right: 4px;
  }
  span {
    font-size: 12px;
    font-weight: 400;
    line-height: 130%;
    color: $white70;
  }
  &.ml10 {
    margin-left: 10px;
  }
  &.ml8 {
    margin-left: 8px;
  }
  &.mt4 {
    margin-top: 4px;
  }
  &.mt8 {
    margin-top: 8px;
  }
  &.noBg {
    padding: 0;
    border-radius: unset;
    backdrop-filter: unset;
    background-color: unset;
  }
}
