@import '../../styles/variables';

.popupContainer {
  width: 100vw;
  left: 0;
  position: absolute;
  top: 0;
  height: 100%;
  z-index: 11;

  .transparentBg {
    width: 100vw;
    left: 0;
    position: absolute;
    top: 0;
    height: 100%;
    z-index: 1;
    background: $halfBlack;
    cursor: pointer;
  }

  .contentSection {
    width: 100vw;
    left: 0;
    position: absolute;
    bottom: 0;
    border-radius: 24px 24px 0px 0px;
    background: $borderWhite;
    backdrop-filter: blur(60px);
    padding: 16px 24px;
    color: $white;
    z-index: 2;
    text-align: left;

    .title {
      font-size: 16px;
      font-weight: 600;
      line-height: 148%;
      margin-bottom: 12px;
    }

    .subTitle {
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 24px;
    }

    .btnContainer {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      button {
        width: auto;
        padding: 10px 26px;
        border-radius: 50px;

        &:first-child {
          margin-right: 8px;
        }

        p {
          margin-bottom: 0;
          font-size: 14px;
          font-weight: 600;
          line-height: 145%;
        }
      }
    }
  }

  .loader {
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $white;
    font-size: 16px;
    line-height: 148%;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2;

    .rotatingLoader {
      width: 32px;
      height: 32px;
      border-radius: 100%;
      border: 2px solid $white90;
      border-right: 1px solid $white40;
      animation: rotate infinite 2s ease-in-out;
      margin-bottom: 16px;
    }
  }

  &.desktopPopup {
    position: fixed;
    z-index: 1;
    width: 53vw;
    left: 23.5vw;

    .transparentBg {
      width: 100%;
    }

    .contentSection {
      width: 414px;
      border-radius: 24px;
      background-color: $borderWhite;
      backdrop-filter: blur(60px);
      margin: 0 auto;
      max-width: 90%;
      left: 50%;
      top: 50%;
      bottom: unset;
      transform: translate(-50%, -50%);
    }
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}