@import '../../styles/variables';

.sharePostSection {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  height: 40px;

  .sharePost {
    color: $darkBlack;
    font-size: 14px;
    font-weight: 400;
    flex: 1;
  }

  .socialIcon {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
}