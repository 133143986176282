@import '../../styles/variables';

.commentThread {
  padding: 16px;
  border-bottom: 1.297px solid $lightWhiteBorder;
  display: flex;
  justify-content: space-between;
  position: relative;

  .authorDetails {
    display: flex;
    flex-grow: 1;

    img {
      border-radius: 100%;
      margin-right: 10px;
      flex-shrink: 0;
      object-fit: cover;
      object-position: top;
    }

    .authorText {
      display: flex;
      flex-direction: column;
      margin-top: 3px;
      flex-grow: 1;

      .commentDetails {
        display: flex;

        .authorComments {
          flex-grow: 1;
        }
      }

      .authorName {
        font-size: 16px;
        font-weight: 500;
        line-height: 130%;
        margin-bottom: 5px;
        color: $white;
        overflow: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        word-wrap: break-word;
      }

      .comment {
        font-size: 14px;
        font-weight: 400;
        line-height: 140%;
        color: $white80;
        word-break: break-word;

        span {
          color: $taglineColor;
          font-weight: 600;
        }
      }

      .stats {
        margin-top: 4px;
        display: flex;
        align-items: center;
      }

      .replyText {
        margin-left: 6px;
        font-size: 10px;
        font-weight: 600;
        line-height: 120%;
        letter-spacing: 0.2px;
        cursor: pointer;
        color: $white70;

        &:hover {
          color: $white;
        }
      }

      .replies {
        width: max-content;
        display: flex;
        padding: 6px 12px;
        justify-content: center;
        align-items: center;
        color: $white80;
        font-size: 10px;
        font-weight: 600;
        line-height: 120%;
        letter-spacing: 0.2px;
        border-radius: 50px;
        border: 1px solid $translucentWhite;
        cursor: pointer;
        margin-top: 4px;

        &:hover {
          color: $white;
          border-color: $blueAscent;

          .caret {
            border-color: $white;
          }
        }

        .caret {
          margin-left: 12px;
          width: 6px;
          height: 6px;
          border-top: 1px solid $white80;
          border-left: 1px solid $white80;
          transform: rotate(225deg);

          &.active {
            transform: rotate(45deg);
            margin-top: 4px;
          }
        }

        .loader {
          margin-left: 12px;
          width: 12px;
          height: 12px;
          border: 1px solid $white80;
          border-bottom-color: transparent;
          border-radius: 100%;
          animation: rotate 2s infinite ease-in-out;
        }
      }
    }
  }

  .time {
    font-size: 13px;
    font-weight: 400;
    line-height: 130%;
    color: $white50;
    align-self: end;
    flex-shrink: 0;
  }

  &.child {
    padding-right: 0;

    &:before {
      content: '';
      width: 31.5px;
      height: 1px;
      background-color: #333;
      position: absolute;
      top: 63px;
      left: -31.5px;
    }
  }

  &.parent {
    &:before {
      content: '';
      width: 1px;
      height: calc(100% - 135px);
      background-color: #333;
      position: absolute;
      top: 63px;
      left: 39.5px;
    }
  }

  &.highlight {
    background-color: $borderWhite;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}