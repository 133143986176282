@import '../../../styles/variables';
@import '../../../styles/breakpoints';

.notificationOverlay {
  display: flex;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background-color: $black70;
  justify-content: flex-end;

  .notificationSection {
    display: flex;
    width: 360px;
    height: 100%;
    flex-direction: column;
    align-items: flex-end;
    flex-shrink: 0;
    backdrop-filter: blur(60px);
    border: 1px solid $borderWhite;
    background: $borderWhite;
  }

  .mobileNotificationSection {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-self: baseline;
    align-items: flex-end;
    flex-shrink: 0;
    border: 1px solid $borderWhite;
    background: $borderWhite;
    backdrop-filter: blur(60px);
  }

  .notificationHeader {
    display: flex;
    gap: 8px;
    width: 100%;
    padding: 8px 8px 8px 16px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $borderWhite;
    color: $white80;

    .notificationBell {
      width: 24px;
      height: 24px;
    }

    p {
      color: $white80;
      font-size: 14px;
      font-weight: 600;
      line-height: 145%;
      flex: 1;
      /* 20.3px */
    }

    .crossIcon {
      width: 32px;
      height: 32px;
      padding: 8px;
      cursor: pointer;
      position: relative;
      flex: unset;

      &:hover {
        background: $translucentWhite;
        border-radius: 100%;
      }

      &:after,
      &:before {
        position: absolute;
        content: '';
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        width: 2px;
        height: 16px;
        background-color: $white80;
      }

      &:after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }

  .notificationBody {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: $borderWhite;
      border-radius: 4px;
      width: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: $translucentWhite;
      width: 4px;
    }

    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.1) transparent;
  }

  .emptyNotificationBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    height: 100%;
    width: 100%;

    .emptyNotificationIcon {
      width: 64px;
      height: 64px;
    }

    p {
      color: $translucentWhite;
      font-size: 14px;
      font-weight: 600;
      line-height: 145%;
      text-align: center;
    }
  }
}