@import '../../styles/variables';
@import '../../styles/breakpoints';

.girlImg {
    position: absolute;
    width: 193px;
    height: 196px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    object-fit: contain;
    @media (max-height: 767px) {
      display: none;
    }
}
.userDetails {
  position: absolute;
  width: calc(100% - 54px);
  bottom: 100%;
  backdrop-filter: blur(60px);
  padding: 20px;
  color: $white;
  display: flex;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 24px;
  overflow: overlay;
  height: max-content;
  max-height: calc(100% - 75px);
  left: 50%;
  transform: translate(-50%, -50%);
  top: -100%;
  &.show {
    top: 50%;
  }
  .userSection {
    justify-content: flex-end;
    display: flex;
    flex-direction: column;
    margin-top: 97px;
    @media (max-height: 767px) {
      margin-top: 0;
    }
  }

  .title {
    margin-bottom: 24px;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    line-height: 125%;
  }

  .genderList {
    display: flex;
    list-style-type: none;
  }

  .ageSelection {
    display: flex;
    list-style-type: none;
    flex-wrap: wrap;
    gap: 12px;
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .notEligible {
    border-radius: 8px;
    border: 1px solid $redAlert;
    padding: 12px;
    background-color: $errorBackground;
    font-size: 14px;
    font-weight: 600;
    line-height: 125%;
    margin-bottom: 20px;
    text-align: center;
  }

  &.desktopUserDetails {
    width: auto;
    padding: 20px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(60px);
    transform: translate(-50%, calc(-50% - 97px));
    max-height: calc(100% - 149px);
    transform: translate(-50%, calc(-50% - 97px));
    &.show {
      top: calc(50% + 97px);
    }
  }
}