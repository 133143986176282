@import '../../styles/variables';

.textBoxSection {
  margin-bottom: 20px;

  .titleSection {
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;

    .title {
      font-size: 10px;
      font-weight: 600;
      line-height: 125%;
      letter-spacing: 1.5px;
      text-transform: uppercase;
      color: $white80;

      &.required {
        position: relative;

        &:after {
          position: absolute;
          content: '*';
          top: 0;
          right: -6px;
          color: $white80;
        }
      }
    }

    .linkTitle {
      font-size: 12px;
      font-weight: 600;
      line-height: 130%;
      color: $taglineColor;
      cursor: pointer;
    }
  }

  .textareaContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 16px;
    border: 2px solid transparent;
    padding: 12px;
    height: 103px;
    justify-content: space-between;
    background: $lightWhiteBorder;

    &:hover {
      border-color: $borderWhite;
    }

    &:focus,
    &:active,
    &:focus-within {
      border-color: $blueAscent;
    }

    .length {
      margin: 0 -4px -4px auto;
      font-size: 10px;
      font-weight: 400;
      line-height: 125%;
      letter-spacing: 0.2px;
      color: $white70;
    }
  }

  .valueInput {
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    outline: none;
    color: $white90;
    resize: none;
    border: none;
    background: transparent;

    &::placeholder {
      color: $white30;
    }

    flex-grow: 1;
  }

  &.desktopTextSection {
    width: 100%;
    margin-bottom: 24px;

    &.mr24 {
      margin-right: 24px;
    }
  }

  &.createPost {
    margin-bottom: 16px;
  }
}