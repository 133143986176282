@import '../../styles/variables';

.emptyList {
  border-radius: 16px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid $borderWhite;

  img {
    width: 32px !important;
    height: auto;
    margin-bottom: 8px;
  }

  .content {
    font-size: 14px;
    font-weight: 400;
    color: $white50;
    text-align: center;
  }

  .button {
    border-radius: 24px;
    padding: 8px 22px;
    font-size: 12px;
    font-weight: 600;
    line-height: 130%;
    color: $white80;
    border: 1px solid $translucentWhite;
    margin: 8px auto 0;
    cursor: pointer;


    &:hover {
      &:not(&:disabled) {
        border-color: $blueAscent;
      }
    }
  }
}