@import '../../styles/variables.scss';

.header {
  width: 100%;
  border-bottom: 1px solid $borderWhite;
  padding: 8px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: 3;
  min-height: 51.5px;
  background: radial-gradient(
      28.18% 45% at 0 58.82%,
      rgba(103, 94, 255, 0.1) 0,
      rgba(103, 94, 255, 0) 100%
    ),
    radial-gradient(
      30.99% 45.35% at 100% 31.43%,
      rgba(255, 89, 236, 0.1) 0,
      rgba(255, 89, 236, 0) 100%
    ),
    radial-gradient(
      28.11% 34.78% at 0 0,
      rgba(255, 189, 78, 0.1) 0,
      rgba(255, 189, 78, 0) 100%
    ),
    linear-gradient(0deg, #171717, #171717), #000;

  @media (max-width: 360px) {
    padding: 8px 12px;
  }

  .headerLogo {
    width: auto;
    height: 24px;
    cursor: pointer;

    @media (max-width: 450px) {
      height: 32px;
    }
  }

  .searchContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      flex-shrink: 0;
      width: auto;
    }
  }

  .headerIcons {
    display: flex;
    align-items: center;

    .iconsContainer {
      display: flex;
      align-items: center;

      .discord {
        width: 32px;
        height: auto;
        padding: 8px;
        border-radius: 100%;
        background-color: $darkBlue;
        flex-shrink: 0;
        cursor: pointer;
        margin-right: 12px;

        @media (max-width: 360px) {
          display: none;
        }

        &:hover {
          background-color: $darkBlueHover;
        }
      }

      .search {
        width: 32px;
        height: auto;
        margin-right: 12px;
        flex-shrink: 0;
        padding: 8px;
        border: 1px solid $blueAscent;
        border-radius: 50%;

        @media (max-width: 400px) {
          margin-left: 12px;
        }
      }

      .divider {
        width: 1px;
        background-color: $borderWhite;
        height: 24px;
        margin: 0 16px;

        &.searchVisible {
          @media (max-width: 400px) {
            display: none;
          }
        }
      }
    }

    .createIconContainer {
      position: relative;
      display: flex;
      width: 32px;
      height: 32px;
      justify-content: center;
      align-items: center;
      border-radius: 50%;

      &.active {
        border-radius: 4px;
      }

      &:hover {
        img {
          background: $purpleBackground;
        }
      }
    }

    .profileIcon {
      width: 32px;
      height: 32px;
      border-radius: 100%;
      cursor: pointer;
    }
  }

  &.desktopHeader {
    min-height: 59.5px;
  }
}

.searchBoxContainer {
  width: 100%;
  position: relative;
  top: 51.5px;
}
