@import '../../styles/variables';
@import '../../styles/breakpoints';

.errorContainer {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 3;
  background-color: $splashBgColor;

  &.landscape {
    left: 0;

    .logo {
      margin-top: 30px;
    }
  }

  @include sm {
    left: 0;
  }

  .logo {
    width: 148px;
    height: auto;
    margin-top: 60px;
    left: 50%;
    position: relative;
    transform: translateX(-50%);
  }
}

.emptyHistory {
  padding: 21px 42px;
  flex-grow: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 83px !important;
    height: auto;
    margin-bottom: 16px;
  }

  p {
    text-align: center;
  }

  .titleText {
    font-size: 18px;
    font-weight: 700;
    line-height: 150%;
    margin-bottom: 16px;
    color: $white;

    .errorContainer & {
      color: $white;
    }
  }

  .noChat {
    color: $white70;
    font-size: 14px;
    margin-bottom: 16px;
    font-weight: 400;
    line-height: normal;

    .errorContainer & {
      color: $white70;
    }
  }

  &.desktopError {
    width: 100%;
  }

  &.nochats {
    img {
      height: 155px;
      width: auto !important;
    }
  }
}
