@import '../../styles/variables';

.genderTab {
  flex: 1;
  margin-right: 16px;
  padding: 12px 0px;
  border-radius: 12px;
  border: 1px solid $translucentWhite;
  background-color: transparent;
  font-size: 16px;
  line-height: 125%;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $white70;
  cursor: pointer;
  &:last-child {
    margin-right: 0;
  }
  &:hover {
    border-color: $white;
    color: $darkBlue;
    background-color: $white80;
    .imageContainer {
      div {
        background-image: url('../../../public/static/assets/images/male_hover.svg');
        &.female {
          background-image: url('../../../public/static/assets/images/female_hover.svg');
        }
        &.other {
          background-image: url('../../../public/static/assets/images/other_hover.svg');
        }
      }
    }
  }
  .imageContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
    text-align: center;
    div {
      width: 36px;
      height: 36px;
      margin-bottom: 8px;
      background-image: url('../../../public/static/assets/images/male.png');
      background-size: contain;
      background-repeat: no-repeat;
      flex-shrink: 0;
      &.female {
        height: 38.317px;
        background-image: url('../../../public/static/assets/images/female.png');
      }
      &.other {
        width: 31px;
        height: 49.813px;
        background-image: url('../../../public/static/assets/images/other.png');
      }
    }
    p {
      text-transform: capitalize;
    }
  }
  &.selected {
    background-color: $white80;
    border-color: $white;
    color: $darkBlue;
    .imageContainer {
      div {
        background-image: url('../../../public/static/assets/images/male_hover.svg');
        &.female {
          background-image: url('../../../public/static/assets/images/female_hover.svg');
        }
        &.other {
          background-image: url('../../../public/static/assets/images/other_hover.svg');
        }
      }
    }
  }
}
