@import '../../styles/variables';

.descriptionModal {
  width: 100vw;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  padding: 16px;
  background-color: $darkBlack;
  z-index: 1;
  animation: fadeIn 0.3s ease-in-out;
  .contentSection {
    flex-grow: 1;
    overflow-y: overlay;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    .name {
      font-weight: 600;
      line-height: 145%;
      color: $white;
      margin-bottom: 8px;
    }
    .description {
      font-weight: 400;
      line-height: normal;
      color: $white70;
      overflow-y: overlay;
    }
  }
  .btnSection {
    padding: 16px;
    display: flex;
    p {
      margin: 0 auto;
      color: $white;
      background-color: $borderWhite;
      font-size: 14px;
      font-weight: 600;
      line-height: 145%;
      padding: 10px 16px;
      cursor: pointer;
      backdrop-filter: blur(60px);
      border-radius: 50px;
      @media (hover: hover) {
        &:hover {
          background-color: $translucentWhite;
        }
      }
    }
  }
  &.chatSlideDescription {
    position: absolute;
  }
  &.desktopCharDescroption {
    width: 46.818vw;
    left: 38.341vw;
  }
  &.charChatDescription {
    width: 23.5vw;
    right: 0;
    left: unset;
    bottom: 56px;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
