@import '../../../styles/variables';
@import '../../../styles/breakpoints';

@keyframes slideInFromRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideDown {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.notificationRow {
  display: flex;
  flex-direction: column;
  padding: 16px 12px;
  align-items: center;
  align-self: stretch;
  height: auto;
  cursor: pointer;

  &:hover {
    background: $borderWhite;

    .notificationInfo {
      .arrow {
        opacity: 1;
        transform: unset;
      }
    }
  }
}

.notificationInfo {
  display: flex;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  height: 100%;

  .errorIcon {
    width: 16px;
    height: 16px;
    margin-right: auto;
    transform: translate(-26px, 4px);
  }

  .characterAvatar {
    width: 48px !important;
    height: 48px !important;
    border-radius: 32px;
    object-fit: cover;
    object-position: top;
  }

  .notificationContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    flex: 1 0 0;
    min-height: 55px;
    justify-content: center;

    .title {
      color: $white;
      font-size: 14px;
      font-weight: 600;
      line-height: 145%;
      align-self: stretch;
    }

    .retrySection {
      display: flex;
      gap: 6px;
      align-self: flex-end;
      align-items: flex-end;
      width: 100%;

      .retry {
        color: $darkBlue;
        text-align: center;
        font-size: 10px;
        font-weight: 600;
        line-height: 120%;
        letter-spacing: 0.2px;
        align-self: flex-end;
        margin-top: 8px;
      }
    }

    .message {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      align-self: stretch;
      overflow: hidden;
      color: $white70;
      text-overflow: ellipsis;
      font-size: 12px;
      font-weight: 400;
      line-height: 130%;

      span {
        color: $darkBlue;
        font-weight: 600;
      }
    }
  }

  .rightSection {
    display: flex;
    flex-direction: column;
    height: 100%;
    place-content: space-between;

    .arrow {
      width: 16px;
      height: 16px;
      opacity: 0;
      align-self: baseline;
      transform: translateX(-6px);
      transition: all 0.2s;
      align-self: flex-end;
    }

    .time {
      font-size: 13px;
      font-weight: 400;
      line-height: 130%;
      color: $white50;
      align-self: flex-end;
      flex-shrink: 0;
    }
  }
}

.divider {
  width: 277px;
  height: 1px;
  background-color: $lightWhiteBorder;
  transform: translateY(16px);
}

.floatingNotificationRow {
  display: unset;
  flex-direction: column;
  padding: 16px 12px;
  align-items: center;
  align-self: stretch;
  background: $borderWhite;
  height: 88px;
  width: 360px;
  cursor: pointer;
  border-radius: 13px;
  box-shadow: 0px 8px 24px -8px rgba(0, 0, 0, 0.16);
  border: 1px solid transparent;
  backdrop-filter: blur(16px);

  &.slideRight {
    animation: slideInFromRight 0.5s forwards;
  }

  &.slideDown {
    animation: slideDown 0.5s forwards;
  }

  &:hover {
    border-color: $borderWhite;
  }

  .close {
    width: 24px;
    height: 24px;
    padding: 6px;
    cursor: pointer;
    transform: translate(322px, -71px);

    &:hover {
      background: $inputFieldBlack;
      border-radius: 50%;
    }
  }
}