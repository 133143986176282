@import '../../styles/variables';

.loader {
  display: flex;
  align-items: center;
  flex-direction: column;

  p {
    font-size: 15.563px;
    font-weight: 500;
    line-height: 120%;
    margin-top: 8px;
    color: $white;
  }
}