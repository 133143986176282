.floatingNotificationSection {
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: absolute;
  top: 64px;
  right: 16px;
  height: auto;
  z-index: 10;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background-color: transparent;
  justify-content: flex-start;
}