@import '../../styles/variables.scss';
@import '../../styles/breakpoints.scss';

.footer {
  display: flex;
  width: 100%;
  bottom: 0;
  position: fixed;
  list-style-type: none;
  z-index: 2;
  border-top: 1px solid #72e9f1;
  border-image: linear-gradient(
    90deg,
    #72e9f166 0%,
    #71a0e566 50%,
    #6257d766 100%
  );
  border-image-slice: 1;
  background: $translucentBlack;
  backdrop-filter: blur(12px);
  &.landscape {
    left: 0;
  }
  @include sm {
    left: 0;
  }
  li {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    text-decoration: none;
    text-transform: capitalize;
    color: $translucentBlack;
    padding: 12px 0px;
    img {
      width: 24px;
      height: auto;
    }
    &.active {
      background: linear-gradient(
          180deg,
          rgba(59, 110, 113, 0.3) 0%,
          rgba(62, 83, 137, 0.3) 50%,
          rgba(52, 46, 122, 0.3) 100%
        ),
        var(--translucents-dark-80, rgba(0, 0, 0, 0.8));
      a {
        color: $linkColor;
      }
    }
  }
}
