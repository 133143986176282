@import '../../styles/variables';

.ageTab {
  padding: 9.5px 0px;
  text-align: center;
  border-radius: 20px;
  border: 1px solid $translucentWhite;
  background-color: transparent;
  width: calc(50% - 6px);
  font-size: 18px;
  line-height: 125%;
  font-weight: 600;
  color: $white70;
  cursor: pointer;
  &:hover {
    border-color: $white;
    color: $darkBlue;
    background-color: $white80;
  }
  &.selected {
    border-color: $white;
    background-color: $white80;
    color: $darkBlue;
  }
}
