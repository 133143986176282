@import '../../styles/variables';
@import '../../styles/breakpoints';

.discordPopup {
  width: 100vw;
  height: 100vh;
  background-color: $black70;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;

  .discordPopupContent {
    width: calc(100vw - 74px);
    max-width: 480px;
    margin: 0 auto;
    border-radius: 12px;
    position: relative;
    backdrop-filter: blur(60px);
    background-color: $borderWhite;

    .crossIcon {
      width: 40px;
      height: 40px;
      position: absolute;
      top: -16px;
      right: -16px;
      border-radius: 100%;
      background-color: #444444CC;
      cursor: pointer;
      backdrop-filter: blur(60px);

      &:hover {
        background-color: #666666CC;
      }

      &:after,
      &:before {
        position: absolute;
        content: '';
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        width: 1px;
        height: 20px;
        background-color: $white;
      }

      &:after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }

    .contentHeader {
      display: flex;
      align-items: center;
      border-radius: 12px 12px 0px 0px;
      background: linear-gradient(0deg,
          rgba(60, 37, 144, 0.8) 0%,
          rgba(60, 37, 144, 0.8) 100%);
      background-repeat: no-repeat;
      background-size: cover;

      .girl {
        height: 175px;
        width: auto;
        margin-right: 38px;
        margin-top: -16px;
        margin-left: -16px;

        @include sm {
          margin-right: 12px;
        }
      }

      .iconsContainer {
        display: flex;
        flex-direction: column;

        .logo {
          height: auto;
          width: 88px;
          margin-bottom: 4px;
        }

        .helloText {
          -webkit-text-stroke-width: 2.6712288856506348px;
          -webkit-text-stroke-color: rgba(255, 255, 255, 0.7);
          font-size: 48.66px;
          font-weight: 900;
          line-height: 100%;
          letter-spacing: 8px;
          text-transform: uppercase;
          margin-bottom: 4px;
          color: transparent;
        }

        .discordText {
          height: auto;
          width: 99px;
        }
      }
    }

    .subContent {
      display: flex;
      align-items: center;
      padding: 24px 16px;
      justify-content: space-between;

      button {
        max-width: 148px;
      }

      .titleSection {
        display: flex;
        flex-direction: column;

        .titleContainer {
          display: flex;
          align-items: center;
          font-size: 16px;
          font-weight: 600;
          line-height: 148%;
          color: $white;
          margin-bottom: 4px;

          .emoji {
            margin-left: 4px;
          }
        }

        .subTitle {
          font-size: 14px;
          font-weight: 400;
          line-height: normal;
          color: $white70;

          span {
            font-weight: 700;
          }
        }
      }

      &.mobile {
        flex-direction: column;

        &.invite {
          flex-direction: row;
        }

        .joinButton {
          width: 114px;

          &.discord {
            width: 100%;
            margin-left: 0;
            margin-top: 12px;
          }
        }
      }

      .joinButton {
        margin-left: 16px;
        width: 148px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $darkBlue;
        background-color: transparent;
        cursor: pointer;
        flex-shrink: 0;
        border-radius: 50px;

        p {
          color: $darkBlue;
        }

        &:hover {
          border-color: $darkBlueHover;

          p {
            color: $darkBlueHover;
          }
        }

        img {
          width: 20px;
          height: auto;
          margin-left: 10px;
        }

        &.discord {
          background-color: $darkBlue;
          border: none;

          p {
            color: $white;
          }

          &:hover {
            background-color: $darkBlueHover;

            p {
              color: $white;
            }
          }
        }
      }

      &.invite {
        border-radius: 0px 0px 12px 12px;
      }
    }
  }
}