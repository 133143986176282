@import '../../styles/variables';

.checkboxContainer {
  .title {
    font-size: 16px;
    font-weight: 700;
    line-height: 100%;
    margin-bottom: 10px;
    color: $white80;
  }

  .reportContentContainer {
    list-style-type: none;
    display: flex;
    flex-direction: column;

    li {
      cursor: pointer;
      padding: 10px;
      margin-bottom: 4px;
      color: $white80;

      @media (hover: hover) {
        &:hover {
          border-radius: 40px;
          background: $borderWhite;

          input[type='checkbox'] {
            border-color: $translucentWhite;
          }
        }
      }

      label {
        display: flex;
        align-items: center;
        cursor: pointer;
      }

      input[type='checkbox'] {
        appearance: none;
        width: 20px;
        height: 20px;
        flex-shrink: 0;
        border-radius: 4px;
        margin-right: 10px;
        border: 1px solid $borderWhite;
        outline: none;

        &:checked {
          background-color: $taglineColor;
          position: relative;
          border: 1px solid $taglineColor;

          &:before {
            content: '';
            font-size: 15px;
            position: absolute;
            right: 5px;
            top: 0px;
            width: 6px;
            height: 12px;
            transform: rotate(30deg);
            border-bottom: 2px solid $black;
            border-right: 2px solid $black;
          }
        }
      }

      &.active {
        border-radius: 40px;
        background: linear-gradient(90deg, rgba(59, 110, 113, 0.30) 0%, rgba(62, 83, 137, 0.30) 50%, rgba(52, 46, 122, 0.30) 100%), $borderWhite;
      }
    }
  }

  &.mb20 {
    margin-bottom: 20px;
  }

  &.desktopCheckBoxContainer {
    flex: 1;

    &.mb20 {
      margin-bottom: 0;
    }
  }
}