@import '../../styles/variables';

.desktopLayout {
  display: flex;
  width: 100vw;
  height: 100%;
  flex-direction: column;
  background: $bodyBackground;

  .contentSection {
    display: flex;
    width: 100vw;
    height: 100%;
    padding-top: 51.5px;
    &.chatContent {
          padding-top: 56px;
    }
    &.desktopContent {
      padding-top: 59.5px;
    }
  }
}
.loadingContainer {
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $loaderBackground;
  z-index: 1;
}
