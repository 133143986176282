@import '../../styles/variables.scss';

.createPost {
  width: 100vw;
  background-color: $black70;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 10;

  .contentSection {
    position: absolute;
    left: 0;
    width: 100vw;
    bottom: 0;
    border-radius: 24px 24px 0px 0px;
    background: $borderWhite;
    backdrop-filter: blur(60px);

    .titleHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 10px 10px 20px;
      border-bottom: 1px solid $borderWhite;

      p {
        &:first-child {
          font-size: 16px;
          font-weight: 600;
          line-height: 148%;
          color: $white;
        }

        &.crossIcon {
          width: 40px;
          height: 40px;
          cursor: pointer;
          position: relative;

          &:hover {
            background: $translucentWhite;
            border-radius: 100%;
          }

          &:after,
          &:before {
            position: absolute;
            content: '';
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(45deg);
            width: 2px;
            height: 20px;
            background-color: $white80;
          }

          &:after {
            transform: translate(-50%, -50%) rotate(-45deg);
          }
        }
      }
    }

    .postUploadedSection {
      display: flex;
      padding: 24px;
      flex-direction: column;
      align-items: center;
      gap: 24px;
      align-self: stretch;

      .postUploaded {
        display: flex;
        align-items: center;
        gap: 10px;
        width: 100%;

        .postLink {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 14px;
          font-weight: 400;
          line-height: 14px;
          color: $white70;
          padding: 13px 16px;
          border-radius: 24px;
          border: 1px solid $borderWhite;
          flex: 1 0 0;
          box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.04);
          background: linear-gradient(
              90deg,
              rgba(59, 110, 113, 0.3) 0%,
              rgba(62, 83, 137, 0.3) 50%,
              rgba(52, 46, 122, 0.3) 100%
            ),
            $borderWhite;
        }

        img {
          width: 40px;
          height: 40px;
          flex-shrink: 0;
          cursor: pointer;
        }

        button {
          width: auto;
          flex-shrink: 0;
        }
      }

      .sharePostSection {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 12px;
        align-self: stretch;

        .sharePost {
          color: $white;
          font-size: 14px;
          font-weight: 400;
          flex: 1;
        }

        .socialIcon {
          width: 40px;
          height: 40px;
          cursor: pointer;
        }
      }
    }

    .createPostSection {
      padding: 24px;
      border-bottom: 1px solid $borderWhite;

      .titleSection {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 6px;

        p {
          &:first-child {
            font-size: 10px;
            font-weight: 600;
            line-height: 125%;
            letter-spacing: 1.5px;
            text-transform: uppercase;
            color: $white80;
          }

          &:last-child {
            font-size: 12px;
            font-weight: 600;
            line-height: 130%;
            color: $taglineColor;
            cursor: pointer;
          }
        }
      }
    }

    .selectedCharacter {
      border-radius: 12px;
      padding: 8px;
      padding-right: 16px;
      background: $borderWhite;
      border: 2px solid transparent;
      margin-bottom: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      position: relative;

      &:hover {
        border: 2px solid $borderWhite;
      }

      .charDescription {
        display: flex;
        align-items: center;
        width: calc(100% - 16px);

        img {
          border-radius: 100%;
          margin-right: 8px;
          object-position: top;
          object-fit: cover;
          flex-shrink: 0;
        }

        .charDetails {
          display: flex;
          flex-direction: column;
          max-width: calc(100% - 60px);

          span {
            &:first-child {
              font-size: 14px;
              font-weight: 600;
              line-height: 145%;
              margin-bottom: 4px;
              color: $white80;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            &:last-child {
              font-size: 12px;
              font-weight: 400;
              line-height: 130%;
              color: $white50;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }

      .caret {
        width: 8px;
        height: 8px;
        border-bottom: 1px solid $white80;
        border-left: 1px solid $white80;
        transform: rotate(-45deg);
        transition: 0.3s all ease-in-out;

        &.active {
          transform: rotate(135deg);
        }
      }

      ul {
        display: flex;
        flex-direction: column;
        list-style-type: none;
        cursor: pointer;
        position: absolute;
        left: -1px;
        width: calc(100% + 2px);
        border-radius: 22px;
        background-color: #222;
        top: 57px;
        max-height: 250px;
        overflow-y: scroll;
        overscroll-behavior: contain;
        z-index: 1;
        border: 1px solid $blueAscent;
        backdrop-filter: blur(60px);

        li {
          padding: 8px;
          border-bottom: 1px solid $borderWhite;
          background: $borderWhite;
          width: 100% !important;

          &.active {
            background: linear-gradient(
                0deg,
                rgba(74, 62, 198, 0.1) 0%,
                rgba(74, 62, 198, 0.1) 100%
              ),
              $translucentWhite;
          }

          &:hover {
            background: $translucentWhite;

            &.active {
              background: linear-gradient(
                  0deg,
                  rgba(74, 62, 198, 0.1) 0%,
                  rgba(74, 62, 198, 0.1) 100%
                ),
                $translucentWhite;
            }
          }
        }
      }
    }

    .mediaTitle {
      font-weight: 600;
      line-height: 125%;
      letter-spacing: 1.5px;
      text-transform: uppercase;
      color: $white80;
      margin-bottom: 6px;
      font-size: 10px;
    }

    .mediaList {
      display: flex;
      list-style-type: none;
      cursor: pointer;
      gap: 12px;

      li {
        flex-grow: 1;
        display: flex;
        align-items: center;
        border-radius: 12px;
        background: $lightWhiteBorder;
        padding: 12px;
        gap: 6px;
        position: relative;
        border: 2px solid transparent;

        img {
          width: 32px;
          height: 32px;
          margin-right: 4px;
        }

        p {
          font-size: 12px;
          font-weight: 600;
          line-height: 130%;
          color: $white70;
        }

        span {
          position: absolute;
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: 125%;
          top: 8px;
          right: 8px;
          color: $redAlert;
          letter-spacing: 0.2px;
        }

        &.active {
          border: 1px solid $blueAscent;
          background: linear-gradient(
              90deg,
              rgba(59, 110, 113, 0.3) 0%,
              rgba(62, 83, 137, 0.3) 50%,
              rgba(52, 46, 122, 0.3) 100%
            ),
            rgba(255, 255, 255, 0.05);
        }

        &:hover {
          border: 2px solid $borderWhite;

          &.active {
            border: 1px solid $blueAscent;
          }
        }

        &.disabled {
          cursor: not-allowed;
          background-color: $borderWhite;

          &:hover {
            border-color: $borderWhite;
          }
        }
      }
    }

    .buttonContainer {
      padding: 16px;
      display: flex;
      gap: 8px;
      justify-content: space-between;

      button {
        width: 134px;

        &:first-child {
          font-size: 14px;
          font-weight: 600;
          line-height: 145%;
          align-self: center;
        }
      }
    }

    .createCharacterSection {
      padding: 24px 44px 32px;

      img {
        width: 100%;
        height: auto;
        margin-bottom: 16px;
      }

      .headerText {
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        line-height: normal;
        color: $white;
        margin-bottom: 8px;
      }

      .subHeaderText {
        font-size: 16px;
        font-weight: 400;
        line-height: 125%;
        color: $white70;
        margin-bottom: 16px;
        text-align: center;
      }

      button {
        width: auto;
        margin: 0 auto;
      }
    }
  }

  &.desktopCreatePost {
    .contentSection {
      width: 480px;
      left: 50%;
      top: 50%;
      bottom: unset;
      transform: translate(-50%, -50%);
      border-radius: 24px;
    }
  }

  &.showLogin {
    width: 76.5vw;
    left: 23.5vw;
    backdrop-filter: blur(60px);
  }
  .imageUpload {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    .imagelabel {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 12px;
      border-radius: 16px;
      border: 1px solid $inputFieldBlack;
      cursor: pointer;
      background-color: $lightWhiteBorder;
      img {
        width: 24px;
        height: auto;
        margin-bottom: 8px;
      }
      p {
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
        color: $white90;
      }
    }
    .uploadedImageSection {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: $lightWhiteBorder;
      padding: 12px;
      border-radius: 16px;
      border: 1px solid $inputFieldBlack;
      .uploadedImage {
        display: flex;
        align-items: center;
        img {
          border-radius: 8px;
          width: 58px;
          height: 58px;
          object-fit: cover;
          object-position: top;
          overflow: hidden;
          margin-right: 8px;
        }
        ul {
          display: flex;
          flex-direction: column;
          list-style-type: disc;
          font-size: 12px;
          font-weight: 400;
          line-height: 130%;
          color: $white90;
          margin: 0;
          padding: 0 0 0 16px;
          text-transform: uppercase;
        }
      }
      .btnContainer {
        display: flex;
        align-items: center;
        button {
          margin-right: 8px;
        }
        div {
          padding: 8px 14px;
          border-radius: 50px;
          border: 1px solid rgba(255, 255, 255, 0.2);
          background-color: transparent;
          color: rgba(255, 255, 255, 0.8);
          box-shadow: unset;
          font-size: 12px;
          line-height: 130%;
          align-items: center;
          cursor: pointer;
          &:hover {
            border-color: $blueAscent;
          }
        }
      }
    }
  }
}
