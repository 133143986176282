@import '../../styles/variables';

.reportSection {
  position: fixed;
  background-color: $black70;
  left: 0;
  top: 0;
  width: 100vw;
  display: flex;
  flex-direction: column;
  z-index: 10;
  bottom: 0;
  height: 100%;
  justify-content: flex-end;

  .contentSection {
    border-radius: 24px 24px 0px 0px;
    background-color: $borderWhite;
    box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.08);
    backdrop-filter: blur(60px);
    width: 100vw;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    li {
      font-size: 14px;
      font-weight: 600;
      line-height: 145%;
      color: $white;
      padding: 10px;
      margin-bottom: 8px;
      margin-top: 8px;
      text-align: center;
      cursor: pointer;

      @media (hover: hover) {
        &:hover {
          background: $translucentWhite;
        }
      }

      &.warning {
        color: $taglineColor;
      }

      &:last-child {
        border-top: 1px solid $borderWhite;
        margin-top: 0;
      }
    }
  }

  .reportContent {
    border-radius: 24px 24px 0px 0px;
    background-color: $borderWhite;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100vw;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    height: 75.5vh;
    backdrop-filter: blur(60px);

    .titleHeader {
      border-bottom: 1px solid $borderWhite;
      display: flex;
      padding: 10px 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 20px;
      font-weight: 600;
      line-height: normal;
      color: $white;

      .crossIcon {
        width: 24px;
        height: 24px;
        cursor: pointer;
        z-index: 2;
        position: relative;

        &:after,
        &:before {
          position: absolute;
          content: '';
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(45deg);
          width: 1.5px;
          height: 15px;
          background-color: $white;
        }

        &:after {
          transform: translate(-50%, -50%) rotate(-45deg);
        }
      }
    }

    .selectionContainer {
      flex-grow: 1;
      overflow-y: overlay;
      display: flex;
      flex-direction: column;
      padding: 20px 20px 0px;
    }

    .btnContainer {
      padding: 16px 20px;
      border-top: 1px solid $borderWhite;
    }
  }

  .chatContent {
    border-radius: 24px 24px 0px 0px;
    background: $borderWhite;
    backdrop-filter: blur(60px);
    padding: 16px 24px;
    color: $white70;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100vw;
    font-weight: 600;
    line-height: 148%;
    font-size: 14px;

    p {
      &:nth-child(1) {
        font-weight: 600;
        line-height: 148%;
        font-size: 16px;
        margin-bottom: 12px;
      }

      &:nth-child(2) {
        font-weight: 400;
        line-height: normal;
        font-size: 14px;
        margin-bottom: 24px;
      }
    }

    .actionButtons {
      display: flex;
      justify-content: flex-end;

      button {
        width: auto;
        padding: 10px 26px;
        border-radius: 50px;

        &:first-child {
          margin-right: 8px;
        }

        p {
          margin-bottom: 0;
          font-size: 14px;
          font-weight: 600;
          line-height: 145%;
        }
      }
    }
  }

  .loader {
    position: absolute;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $white;
    font-size: 16px;
    line-height: 148%;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .rotatingLoader {
      width: 32px;
      height: 32px;
      border-radius: 100%;
      border: 2px solid $white90;
      border-right: 1px solid $white40;
      animation: rotate infinite 2s ease-in-out;
      margin-bottom: 16px;
    }
  }

  &.desktopReportSection {
    position: absolute;
    width: 210px;
    height: auto;
    right: 12px;
    top: 108px;
    left: unset;
    bottom: unset;
    background-color: transparent;

    .contentSection {
      width: 210px;
      border-radius: 24px;

      li {
        margin: 0;
        border: unset;
        padding: 18px 10px;
      }
    }

    &.desktopChatsReport {
      right: calc(23.5vw + 12px);
    }

    &.desktopClearChats {
      top: 108px;
    }
  }

  &.desktopReportModal {
    .reportContent {
      width: 560px;
      max-width: 90%;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;
      border-radius: 24px;
      bottom: unset;
      height: unset;

      .selectionContainer {
        flex-direction: row;
        padding: 20px;

        .divider {
          width: 1px;
          margin: 0 24px;
          background-color: rgba(0, 0, 0, 0.1);
        }
      }
    }

    .chatContent {
      width: 414px;
      max-width: 90%;
      padding: 16px;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;
      border-radius: 24px;
      bottom: unset;
      height: unset;
    }
  }

  .successToast {
    display: flex;
    justify-content: space-between;
    padding: 12px;
    border-radius: 24px 24px 0px 0px;
    background: $borderWhite;
    backdrop-filter: blur(60px);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100vw;
    box-shadow: 0px -8px 24px 0px rgba(0, 0, 0, 0.08);

    .successContainer {
      display: flex;
      align-items: center;

      .successIcon {
        width: 32px;
        height: 32px;
        margin-right: 12px;
        background-color: $borderGreen;
        border-radius: 100%;
        position: relative;

        &:before {
          content: '';
          font-size: 15px;
          position: absolute;
          right: 11px;
          top: 7px;
          width: 6px;
          height: 12px;
          transform: rotate(30deg);
          border-bottom: 2px solid $white;
          border-right: 2px solid $white;
        }
      }

      .successText {
        font-size: 16px;
        font-weight: 600;
        line-height: 148%;
        color: $white80;
      }
    }

    .crossIcon {
      width: 32px;
      height: 32px;
      margin-left: 12px;
      cursor: pointer;
      z-index: 2;
      position: relative;

      &:after,
      &:before {
        position: absolute;
        content: '';
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        width: 1px;
        height: 20px;
        background-color: $white80;
      }

      &:after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }

      @media (hover: hover) {
        &:hover {
          border-radius: 100%;
          background-color: $translucentWhite;
        }
      }
    }

    &.desktopToast {
      bottom: 120px;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 24px;
      width: auto;
    }
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
