@import '../../styles/variables';

.createCharacter {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  .loadingContainer {
    width: 100vw;
    height: 100vh;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $loaderBackground;
    z-index: 1;
  }

  .contentSection {
    padding: 20px;
    flex-grow: 1;
    overflow-y: overlay;

    .halfFlexRox {
      display: flex;
      flex-direction: column;
      gap: 0px;
    }

    .nameSection {
      margin-bottom: 20px;

      p {
        font-size: 10px;
        font-weight: 600;
        line-height: 125%;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        color: $white80;
        position: relative;
        margin-bottom: 4px;
        display: inline-block;

        &:after {
          position: absolute;
          content: '*';
          top: 0;
          right: -6px;
          color: $white80;
        }
      }

      .nameInput {
        width: 100%;
        border-radius: 24px;
        border: 1px solid transparent;
        background: $borderWhite;
        padding: 10px 16px;
        font-size: 14px;
        font-weight: 400;
        color: $white80;
        outline: none;

        &:hover {
          border-color: $borderWhite;
        }

        &:focus,
        &:active {
          border-color: $blueAscent;
        }
      }
    }

    .genderSection {
      margin-bottom: 20px;

      .titleText {
        font-size: 10px;
        font-weight: 600;
        line-height: 125%;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        color: $white80;
        position: relative;
        margin-bottom: 6px;
        display: inline-block;

        &:after {
          position: absolute;
          content: '*';
          top: 0;
          right: -6px;
          color: $white80;
        }
      }

      .genders {
        display: flex;
        justify-content: space-between;
        list-style-type: none;

        li {
          flex: 1;
          padding: 10px 8px 10px 12px;
          display: flex;
          align-items: center;
          margin-right: 16px;
          border-radius: 50px;
          border: 1px solid $translucentWhite;
          justify-content: space-between;
          cursor: pointer;
          width: 100%;

          &:last-child {
            margin-right: 0;
          }

          p {
            font-size: 12px;
            font-weight: 600;
            line-height: 130%;
            color: $white70;
            margin-right: 4px;
          }

          span {
            width: 20px;
            height: 20px;
            border-radius: 100%;
            background-color: $borderBlack;
            position: relative;
          }

          &:hover {
            border-color: $white50;

            &.active {
              border-color: $taglineColor;

              span {
                background-color: $taglineColor;

                &:after {
                  width: 4px;
                  height: 9px;
                  border-bottom: 2px solid $black;
                  border-right: 2px solid $black;
                  position: absolute;
                  top: 3px;
                  left: 7px;
                  transform: rotate(38deg);
                  content: '';
                }
              }
            }
          }

          &.active {
            border-color: $taglineColor;

            span {
              background-color: $taglineColor;

              &:after {
                width: 4px;
                height: 9px;
                border-bottom: 2px solid $black;
                border-right: 2px solid $black;
                position: absolute;
                top: 3px;
                left: 7px;
                transform: rotate(38deg);
                content: '';
              }
            }
          }
        }
      }
      .divider {
        flex: 1;
        display: block;
        margin-right: 16px;
      
        &:last-child {
          margin-right: 0;
        }
      }
      .flexRow {
        display: flex;
        align-items: center;
        gap: 8px;
      }
      
      .subText {
        font-size: 12px; 
        font-weight: 400; 
        line-height: 130%; 
        letter-spacing: 0px; 
        color: #aaa; 
        margin-top: 2px;                                 
        text-align: left; 
        width: 100%;
      }
      
      .ageInput {
        width: 100px;
        flex: 1;
        padding: 10px 8px 10px 12px;
        margin-right: 16px;
        border-radius: 50px;
        border: 1px solid $translucentWhite;
        background-color: transparent;
        font-size: 12px; 
        font-weight: 600;
        line-height: 130%;
        color: $white70;
        outline: none;

        
        &.error {
          border-color: #DE5A48 !important;
        }
        &.error:active {
          border-color: #DE5A48 !important;
        }
       }
       input[type=number]::-webkit-outer-spin-button,
       input[type=number]::-webkit-inner-spin-button {
         -webkit-appearance: none;
         margin: 0;
       }
      
      .ageWarning {
        width: 380px;
        font-size: 12px; 
        font-weight: 400; 
        line-height: 130%; 
        letter-spacing: 0px; 
        color: $white50; 
        margin-top: 4px; 
      }
      .errorText {
        font-size: 12px;
        font-weight: 600; 
        line-height: 130%; 
        letter-spacing: 0px; 
        color: #DE5A48; 
        vertical-align: middle;
      }
    }

    .additionalOptions {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
      align-self: stretch;
      border-top: 1px solid $borderWhite;
      border-bottom: 1px solid $borderWhite;
      padding: 19px 0px;

      .additionalOptionsHeader {
        display: flex;
        align-items: flex-start;
        gap: 8px;
        width: 100%;
        align-items: center;
        cursor: pointer;

        p {
          color: $taglineColor;
          font-size: 14px;
          font-weight: 600;
          line-height: 145%;
          flex: 1;
        }

        img {
          transform: rotate(90deg);
        }
      }

      .additionalOptionsContent {
        display: flex;
        background-color: transparent;
        width: 100%;
        flex-direction: column;

        .commSection {
          margin-bottom: 20px;
    
          .titleText {
            font-size: 10px;
            font-weight: 600;
            line-height: 125%;
            letter-spacing: 1.5px;
            text-transform: uppercase;
            color: $white80;
            position: relative;
            margin-bottom: 6px;
            display: inline-block;
    
            &:after {
              position: absolute;
              content: '*';
              top: 0;
              right: -6px;
              color: $white80;
            }
          }
    
          .commStyles {
            display: flex;
            justify-content: space-between;
            list-style-type: none;
    
            li {
              flex: 1;
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-right: 16px;
              border-radius: 12px;
              border: 1px solid $translucentWhite;
              justify-content: space-between;
              cursor: pointer;
    
              &:last-child {
                margin-right: 0;
              }

              .listHeader {
                display: flex;
                align-items: center;
                border-radius: 50px;
                padding: 10px 8px 10px 12px;
                width: 100%;

                p {
                  font-size: 12px;
                  font-weight: 600;
                  line-height: 130%;
                  color: $white70;
                  margin-right: 4px;
                  flex: 1;
                }
      
                span {
                  width: 20px;
                  height: 20px;
                  border-radius: 100%;
                  background-color: $borderBlack;
                  position: relative;
                }
              }

              .chatWrapper {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                border-radius: 16px 16px 16px 0px;
                background: #393535;
                padding: 8px 12px 14px 12px;
                margin: 0px 25px 12px 12px;
                gap: 8px;
                align-self: stretch;

                .desc {
                  color: $white50;
                  font-size: 14px;
                  font-style: italic;
                  font-weight: 400;
                  line-height: 130%;
                  align-self: stretch;
                }


                .content {
                  color: $white;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  align-self: stretch;
                }
              }
    
              &:hover {
                border-color: $white50;
    
                &.active {
                  border-color: $taglineColor;

                  .listHeader {
                    span {
                      background-color: $taglineColor;
      
                      &:after {
                        width: 4px;
                        height: 9px;
                        border-bottom: 2px solid $black;
                        border-right: 2px solid $black;
                        position: absolute;
                        top: 3px;
                        left: 7px;
                        transform: rotate(38deg);
                        content: '';
                      }
                    }
                  }
                }
              }
    
              &.active {
                border-color: $taglineColor;
    
                .listHeader {
                  span {
                    background-color: $taglineColor;
      
                    &:after {
                      width: 4px;
                      height: 9px;
                      border-bottom: 2px solid $black;
                      border-right: 2px solid $black;
                      position: absolute;
                      top: 3px;
                      left: 7px;
                      transform: rotate(38deg);
                      content: '';
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    
    .active {
      padding-bottom: 0px;

      .additionalOptionsHeader {
        img {
          transform: rotate(-90deg);
        }
      }
    }
  }

  .buttonContainer {
    padding: 8px 20px 16px;
    position: sticky;
    bottom: 0;
    border-top: 1px solid $borderWhite;
    justify-items: center;
    background: radial-gradient(
        28.18% 45% at 0 58.82%,
        rgba(103, 94, 255, 0.1) 0,
        rgba(103, 94, 255, 0) 100%
      ),
      radial-gradient(
        30.99% 45.35% at 100% 31.43%,
        rgba(255, 89, 236, 0.1) 0,
        rgba(255, 89, 236, 0) 100%
      ),
      radial-gradient(
        28.11% 34.78% at 0 0,
        rgba(255, 189, 78, 0.1) 0,
        rgba(255, 189, 78, 0) 100%
      ),
      linear-gradient(0deg, #171717, #171717), #000;
    .warningText {
      font-size: 12px;
      font-weight: 400;
      line-height: 130%;
      margin-bottom: 8px;
      color: $redAlert;
      text-align: center;

      span {
        font-weight: 600;
      }
    }

    button {
      max-width: 100%;
      height: 40px;
    }
  }

  &.desktopCharCreate {
    width: 76.5vw;

    .buttonContainer {
      display: flex;
      justify-content: center;
      background-color: transparent;
      align-items: center;
      padding: 10px 3.26%;

      .warningText {
        margin-bottom: 0;
        margin-right: 10px;
      }

      button {
        max-width: 200px;
        height: 40px;
      }
    }

    .contentSection {
      display: flex;
      background-color: transparent;
      width: 100%;
      padding: 24px 27%;
      flex-direction: column;

      .halfFlexRox {
        display: flex;
        flex-direction: row;
        gap: 24px;
      }

      .nameSection,
      .genderSection {
        width: 100%;
        margin-bottom: 24px;
      }

      .imageStyles {
        width: 100%;

        ul {
          li:nth-of-type(4n + 4) {
            margin-right: 0;
          }

          flex-wrap: wrap;
          overflow: unset;

          li {
            cursor: pointer;
            width: calc((100% - 36px) / 5);

            img {
              width: 100%;
              height: auto;
            }
          }
        }
      }
    }

    .loadingContainer {
      width: 76.5vw;
    }
  }
}

.createCharContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  header {
    padding: 8px 12px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $lightWhiteBorder;
    background: $lightWhiteBorder;
    position: sticky;
    top: 0;
    z-index: 1;

    img {
      width: 40px;
      height: 40px;
      padding: 10px;
      margin-right: 6px;
      cursor: pointer;
    }

    p {
      font-size: 16px;
      font-weight: 600;
      line-height: 148%;
      color: $white80;
    }

    &.desktopHeader {
      width: 53vw;
    }
  }

  .stepHighlight {
    width: 100%;
    height: 5px;
    display: flex;
    justify-content: space-between;
    background-color: $borderWhite;
    position: sticky;
    top: 57px;
    z-index: 1;

    span {
      width: 20%;
      height: 5px;

      &.active {
        background: linear-gradient(90deg, #3b6e71 0%, #3e5389 100%);
      }

      &.completed {
        background: linear-gradient(90deg, #3b6e71 0%, #3e5389 100%);
      }
    }

    &.desktopSteps {
      width: 53vw;
    }
  }

  .createCharContent {
    display: flex;
    height: calc(100% - 62px);
    width: 100%;
  }

  .desktopCreateCharContent {
    width: 76.5vw;
  }
}

.charPreview {
  width: 23.5vw;
  height: 100%;
  flex-shrink: 0;
}
