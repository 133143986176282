@import '../../styles/variables';

.nsfwSwitch {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  gap: 2px;

  p {
    &:first-child {
      font-size: 11px;
      font-weight: 400;
      line-height: normal;
      color: $white60;
      text-transform: uppercase;
      letter-spacing: 0.56px;
      transition: all 0.3s ease-in-out;
    }

    &.switch {
      width: 32px;
      height: 14px;
      border: 1.65px solid $white60;
      border-radius: 8px;
      position: relative;
      transition: all 0.3s ease-in-out;
      background-color: transparent;

      span {
        position: absolute;
        height: 7px;
        width: 7px;
        left: 2px;
        top: 50%;
        transform: translateY(-50%);
        background-color: $white60;
        border-radius: 100%;
        transition: all 0.3s ease-in-out;
      }
    }
  }

  &.nsfwEnabled {
    .switch {
      background-color: $redAlert;
      border: 1.65px solid $redAlert;

      span {
        left: 19px;
        background-color: $white;
      }
    }

    p {
      &:first-child {
        color: $redAlert;
      }
    }
  }

  &.error {
    justify-content: unset;
    flex-direction: row;

    p {
      &:first-child {
        color: $borderRed;
        margin-right: 4px;
        letter-spacing: unset;
      }
    }

    .switch {
      border: 1.65px solid $borderRed;

      span {
        background-color: $borderRed;
      }
    }

    &.nsfwEnabled {
      .switch {
        background-color: $borderRed;

        span {
          background-color: linear-gradient(0deg,
              rgba(243, 54, 33, 0.12) 0%,
              rgba(243, 54, 33, 0.12) 100%),
            $white;
        }
      }
    }
  }
}