@import '../../styles/variables';

.primaryButton {
  padding: 9.5px 24px;
  background-color: $white90;
  color: $darkBlue;
  font-size: 18px;
  font-weight: 600;
  line-height: 150%;
  border-radius: 20px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 500px;
  text-transform: capitalize;
  border: none;
  display: flex;
  justify-content: center;
  text-decoration: none;

  &:hover {
    background-color: $white;
    color: $darkBlueHover;
  }

  &.blue {
    font-size: 18px;
    line-height: 125%;
    padding: 17px 31px;
    background-color: $darkBlue;
    color: $white90;
    border-radius: 65px;
    &:hover {
      background-color: $darkBlueHover;
      color: $white;
    }
    &:disabled {
      backdrop-filter: none;
    }
  }

  &.createChar {
    padding: 10px 16px;
    color: $white;
    background-color: $darkBlue;
    font-size: 14px;
    font-weight: 600;
    line-height: 145%;
    box-shadow: unset;

    &:hover {
      opacity: 0.9;
    }

    &:disabled {
      background-color: $borderBlack;
    }
  }

  &.createCharPrimary {
    padding: 10px 16px;
    color: $darkBlue;
    background-color: $white;
    font-size: 14px;
    font-weight: 600;
    line-height: 145%;
    box-shadow: unset;
    border: 1px solid $charBtnBorder;

    &:hover {
      opacity: 0.9;
    }

    &:disabled {
      opacity: 0.5;
      background-color: $white;
    }
  }

  &.gradient {
    border-radius: 50px;
    background: linear-gradient(104deg,
        $charBtnBorder 31.09%,
        $blueGradientBtn 72.12%);
    box-shadow: 0px 8px 24px -8px rgba(62, 110, 198, 0.6);
    padding: 10px 16px;
    font-size: 14px;
    font-weight: 600;
    line-height: 145%;
    color: $white;
    width: auto;
    margin: 0 auto;
  }

  &.darkBlue {
    padding: 13px 24px;
    border-radius: 65px;
    background-color: $darkBlue;
    color: $white;
    font-size: 14px;
    font-weight: 600;
    line-height: 125%;

    &:hover {
      background-color: $darkBlueHover;
    }
  }

  &.blueExplore {
    padding: 10px 16px;
    border-radius: 8px;
    background-color: $darkBlue;
    color: $white;
    font-size: 14px;
    font-weight: 600;
    line-height: 145%;

    &:hover {
      background-color: $darkBlueHover;
    }
  }

  &.primaryWhite {
    padding: 13px 24px;
    border-radius: 65px;
    color: $buttonColor;
    font-size: 14px;
    font-weight: 600;
    line-height: 125%;
  }

  &.outline {
    padding: 8px 14px;
    border-radius: 50px;
    border: 1px solid $translucentWhite;
    background-color: transparent;
    color: $white80;
    box-shadow: unset;
    font-size: 12px;
    line-height: 130%;
    align-items: center;

    &:hover {
      &:not(&:disabled) {
        border-color: $blueAscent;
      }
    }
  }

  &.whiteTransparent {
    padding: 10px 16px;
    border-radius: 20px;
    background-color: $borderWhite;
    color: $white;
    font-size: 14px;
    font-weight: 400;

    &:hover {
      background-color: $translucentWhite;
    }
  }

  &:disabled {
    cursor: not-allowed;
    background-color: $borderWhite;
    color: $white40;
    backdrop-filter: blur(12px);
  }
}