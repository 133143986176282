@import '../../styles/variables';
@import '../../styles/breakpoints';

.notificationBell {
  height: 32px;

  .notificationBellImg {
    width: 32px !important;
    height: 32px !important;
    margin-right: 12px;
    padding: 8px;
    border-radius: 100%;
    flex-shrink: 0;
    cursor: pointer;
    border: 1px solid $blueAscent;

    &:hover {
      background: $purpleBackground;
    }
  }
}

.notificationDot {
  display: flex;
  min-width: 16px;
  height: 16px;
  justify-content: center;
  align-items: center;
  border-radius: 32px;
  background: $redAlert;
  position: absolute;
  transform: translate(20px, -40px);

  .notificationCount {
    color: $white;
    text-align: center;
    font-size: 10px;
    font-weight: 600;
    line-height: 125%;
    letter-spacing: 1.5px;
    width: 100%;
    text-align: center;
    padding-left: 1px;
  }
}