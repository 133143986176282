@import '../../styles/variables';

.cardModal {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 11;

  .overlay {
    backdrop-filter: blur(12px);
    background-color: $black70;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .modalContent {
    padding: 24px 32px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 12px;
    background-color: $borderWhite;
    backdrop-filter: blur(60px);
    max-width: 305px;
    max-height: 540px;
    width: calc(100% - 110px);
    height: 68%;
    text-align: center;
    z-index: 1;

    img {
      width: 241px;
      height: auto;
      margin-bottom: 24px;
    }

    .title {
      margin-bottom: 8px;
      font-size: 20px;
      color: $white;
      font-weight: 600;
    }

    .subTitle {
      font-size: 14px;
      font-weight: 400;
      color: $white80;
      margin-bottom: 24px;
    }
  }
}