@import '../styles/variables';

.home {
  width: 100%;
  height: 100%;
  background: url('../../public/static/assets/images/splash.webp') lightgray top left / cover no-repeat;
  overflow-x: hidden;
  background-position: center;
  .overlay {
    width: 100%;
    height: 100%;
    background-color: $black70;
  }
  &.desktopHome {
    background: url('../../public/static/assets/images/desktopLogo.webp') lightgray top left / cover no-repeat;
  }
}
.loadingContainer {
  position: absolute;
  z-index: 11;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $loaderBackground;
}
