@import '../../styles/variables';

.searchBar {
  margin: 16px 16px 0;
  display: flex;
  align-items: center;
  border-radius: 24px;
  background-color: $borderWhite;
  border: 2px solid transparent;
  overflow: hidden;
  padding: 10px 16px;
  flex-shrink: 0;
  flex-grow: 1;
  cursor: pointer;

  &:hover {
    border: 2px solid $borderWhite;
  }

  .search {
    font-size: 16px;
    font-weight: 400;
    color: $white90;
    outline: none;
    background-color: transparent;
    flex-grow: 1;
    border: none;
    margin-right: 4px;
    order: 1;

    &::placeholder {
      color: $white60;
    }

    &::-webkit-search-cancel-button {
      position: relative;
      background: $white;
      color: $white;
      appearance: none;
      background: url('../../../public/static/assets/images/crossIcon.png');
      background-size: cover;
      width: 32px;
      height: 32px;
      cursor: pointer;
      margin-left: 8px;
      transform: translateX(12px);
    }

    &:hover {
      &:not(:focus) {
        &::-webkit-search-cancel-button {
          display: none;
        }
      }
    }

    &:focus {
      order: 2;
      margin-top: 1px;

      ~.searchImage {
        order: 1;
        margin-right: 4px;
        display: none;
      }
    }
  }

  .searchImage {
    width: 20px !important;
    height: auto;
    order: 2;
  }

  &.desktopSearch {
    max-width: 360px;
    min-width: 360px;
    margin: 0;
    width: 100%;
  }

  &.mr12 {
    margin-right: 12px;
  }

  &.margin12 {
    margin: 12px;
  }
}